<template>
  <div class="info-active-city">
    <div class="info-active-city__city-name h4">{{ cityName }}</div>
    <div class="info-active-city__list">
      <div
        v-if="address"
        class="info-row"
      >
        <div class="info-row__icon">
          <img
            src="~/assets/img/svg/common/pin.svg"
            width="20"
            height="20"
            alt="map-pin"
          />
        </div>
        <div
          class="info-row__value --black h5 semibold"
          v-html="address"
        />
      </div>
      <div
        v-if="phoneNumber"
        class="info-row --mobile-wrap"
      >
        <div class="info-row__icon">
          <img
            src="~/assets/img/svg/common/phone.svg"
            width="20"
            height="20"
            alt="phone"
          />
        </div>
        <a
          :href="`tel:${phoneNumber}`"
          class="info-row__value h5 semibold hover-decoration"
          target="_blank"
        >
          {{ getPhoneNumberFormat(phoneNumber) }}
        </a>
        <a
          v-if="phoneWhatsapp"
          :href="`https://api.whatsapp.com/send?phone=${phoneWhatsapp}`"
          class="info-row__link"
          target="_blank"
          rel="nofollow"
        >
          Написать в WhatsApp
        </a>
      </div>
      <div
        v-if="workingHours"
        class="info-row"
      >
        <div class="info-row__icon">
          <img
            src="~/assets/img/svg/common/clock.svg"
            width="20"
            height="20"
            alt="clock"
          />
        </div>
        <div
          class="info-row__value --black h5 semibold"
          v-html="workingHours"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import {
  getPhoneNumberClear,
  getPhoneNumberFormat
} from '~/utils/phoneNumber';

const props = defineProps({
  information: {
    type: Object as any,
    default: () => {}
  }
});

const cmsData = computed(() => {
  return (props?.information?.city?.cms_data || []);
});

const cityName = computed(() => {
  return props?.information?.city?.city_name || '';
});
const address = computed(() => {
  return cmsData.value.find((t: any) => t.var === 'Адрес')?.value || ''
});
const phoneNumber = computed(() => {
  const phone = cmsData.value.find((t: any) => t.var === 'Телефон')?.value || ''
  return getPhoneNumberClear(phone);
});
const phoneWhatsapp = computed(() => {
  return cmsData.value.find((t: any) => t.var === 'Whatsapp')?.value || ''
});
const workingHours = computed(() => {
  return cmsData.value.find((t: any) => t.var === 'График работы')?.value || ''
});
</script>

<style scoped lang="scss">
.info-active-city {
}

.info-active-city__city-name {
	margin-bottom: 24px;
	color: black;
}

.info-active-city__list {
	display: flex;
	flex-direction: column;

	& > * {
		margin-top: 24px;

		&:first-child {
			margin-top: 0;
		}
	}
}

.info-row {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.info-row__icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 28px;
	height: 28px;
	border-radius: 7px;
	background: #F4F3F1;

	img {
		width: 20px;
		height: 20px;
		object-fit: contain;
	}
}

.info-row__value {
	margin-left: 16px;

	&.--black {
		flex: 1;
		color: black;
	}
}

.info-row__link {
	margin-left: 15px;

	color: #333;
	font-size: 12px;
	font-weight: 500;
	text-decoration: underline;
	transition: all 0.2s;

	&:hover {
		text-decoration-color: transparent;
	}
}

@media (max-width: 1023px) {
	.info-active-city__city-name {
		text-align: center;
	}

	.info-row {
		justify-content: center;

		&.--mobile-wrap {
			flex-wrap: wrap;
			order: 3;
		}
	}
	.info-row__icon {
		min-width: 28px;
	}
	.info-row__value {
		flex: initial !important;
		margin-left: 8px;
	}
	.info-row__link {
		width: 100%;
		margin-top: 24px;
		text-align: center;
	}
}
</style>
