<template>
  <section class="stores-block">
    <div class="container">
      <h2 class="stores-block__title">Адреса наших шоурумов </h2>
      <div class="stores-block__map">
        <div class="stores-block-information">

          <div v-if="false">
            <div class="stores-block-information__title h3 semibold"> Адреса наших шоурумов </div>
          </div>

          <div class="stores-block-information__message">
            Приглашаем посетить фирменные шоурумы и лично оценить качество используемых материалов, фурнитуры и тканей.
          </div>
          <div class="stores-block-information__cities">
            <div
              v-for="(city, index) in cities"
              :key="`city-${city.id}`"
              :class="{'active': Boolean(index === activeCityIndex)}"
              @click="() => changeActiveCity(index)"
            >
              {{ city.city_name }}
            </div>
          </div>
          <div class="stores-block-information__delimiter" />
          <InfoActiveCity
            :information="activeCityInfo"
          />
        </div>

        <div
          ref="mapRef"
          class="stores-block__map-content"
        >
          <iframe
            v-if="isShowMap"
            title="Адреса наших шоурумов"
            :src="mapIframe"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';
import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import InfoActiveCity from '~/components/stores-block/InfoActiveCity.vue';

const props = defineProps<IBlockDefaultProps>();
const mapRef = ref<HTMLButtonElement>();
const isShowMap = ref(false);

const cities = computed(() => {
  let _list: any = [];
  Object.keys((props?.extras?.cities || {})).map((objectKey) => {
    _list.push(props?.extras?.cities[objectKey])
  })
  return _list.sort((a: any, b: any) => {
    if (a?.sort < b?.sort) {
      return 1
    }
    if (a?.sort > b?.sort) {
      return -1
    }
    return 0
  })
});

const activeCityInfo = computed(() => {
  const city = cities.value[activeCityIndex.value];
  const store = (props?.extras?.stores || []).find((t: any) => t.city_id === city.id);
  return {
    city,
    store
  };
});

const mapIframe = computed(() => {
  return activeCityInfo.value?.store?.map_embed || '';
});

const activeCityIndex: any = ref(0);
const changeActiveCity = (val: any) => {
  activeCityIndex.value = val;
};
const { stop } = useIntersectionObserver(
  mapRef,
  ([{ isIntersecting }]) => {
    if (isIntersecting) {
      isShowMap.value = true;
      stop();
    }
  }
);

onMounted(() => {
  isShowMap.value = true;
});
</script>

<style lang="scss" scoped>
.stores-block {
	padding: 62px 0;
	box-sizing: border-box;
}

.stores-block__title {
	margin-bottom: 32px;
}

.stores-block__map {
	display: flex;
	justify-content: flex-start;
	min-height: 600px;
	padding: 36px 24px;
	box-sizing: border-box;
	position: relative;
	overflow: hidden;
	z-index: 0;
	border-radius: 20px;
}

.stores-block-information {
	width: 100%;
	max-width: 550px;
	border-radius: 40px;
	background: #FFF;
	box-shadow: 0 4px 36px 0 rgb(138 138 138 / 10%);
	padding: 40px;
	box-sizing: border-box;
}

.stores-block-information__title {
}

.stores-block-information__message {
	margin-top: 12px;
	margin-bottom: 24px;
	max-width: 440px;
}

.stores-block-information__cities {
	display: flex;
	flex-wrap: wrap;
	margin-top: -8px;
	margin-left: -8px;

	& > * {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 8px 24px;
		box-sizing: border-box;
		border-radius: 8px;
		border: 1px solid #EBEBEB;
		margin-top: 8px;
		margin-left: 8px;
		background-color: white;
		cursor: pointer;
		color: #000;
		font-size: 16px;
		font-weight: 500;
		line-height: 150%;

		&.active {
			background-color: #F4F3F1;
		}
	}
}

.stores-block-information__delimiter {
	margin: 32px 0;
	width: 100%;
	height: 1px;
	background-color: #EBEBEB;
}

.stores-block-information__city-name {
	margin-bottom: 24px;
}

.stores-block__map-content {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: none;
	}
}

@media (max-width: 1023px) {
	.stores-block {
		padding-bottom: 0;
	}

	.stores-block__map {
		flex-direction: column-reverse;
		margin: 0 -20px;
		padding: 0;
	}
	.stores-block__map-content {
		position: relative;
		margin-bottom: -26px;

		&:after {
			content: "";
			float: left;
			padding-top: 90.2%;
		}
	}
	.stores-block-information {
		border-radius: 24px 24px 0 0;
		padding: 40px 20px;
	}
	.stores-block-information__title {
		text-align: center;
	}
	.stores-block-information__message {
		margin-top: 15px;
		margin-bottom: 16px;
		text-align: center;
	}
	.stores-block-information__cities {
		justify-content: center;
		align-items: center;
		max-width: initial;

		& > * {
			padding: 8px 24px;

			font-size: 12px;
			line-height: 100%;
		}
	}
	.stores-block-information__delimiter {
		margin: 23px 0;
	}
}
</style>
